import React from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Layout from "../components/layout"
import { Link } from "gatsby"

const CadrePhotoNoir = (props) => {
  return (
    <>
      <SEO title="Cadre photo noir" description="Cadre photo noir avec passe partout formats 50x70, 30x40, 40x50, A2, A3" canonical={`${process.env.GATSBY_URL_ACTUEL}${props.location.pathname}`} />
      <Layout banner={false} location={props.location}>
        <div className="container">
          <h1>Cadre photo noir</h1>
          <div className="columns is-centered">
            <div className="column">
              <p>Nos cadres photo noirs les plus populaires :</p>
              <div>
                <ul style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", textAlign: "center"}}>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-BRATAN-20-Noir-89/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/bratan20-croquis.svg" alt="Bratan 20" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">Bratan 20</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 2cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir satiné</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 20,30€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-BRATAN-30-Noir-95/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/bratan30-croquis56.svg" alt="Bratan 30" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">Bratan 30</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 3cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir satiné</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 22,96€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-aluminium-ALU-010-Noir-1403/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/alulack.svg" alt="ALU 010" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">ALU 010</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 1cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir satiné</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 33,69€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-VEGAS-15-Noir-430/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/vegas15-croquis.svg" alt="VEGAS 15" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">VEGAS 15</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 1.4cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir laqué</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 21,57€*</div>
                    </Link>
                  </li>
                </ul>
                <ul style={{display: "flex", flexWrap: "wrap", justifyContent: "space-evenly", textAlign: "center"}}>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-ALBA-15-Noir-15/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/alba15-croquis.svg" alt="ALBA 15" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">ALBA 15</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 1.5cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir mat</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 22,11€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-PLANA-25.15-Noir-1755/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/alba25-croquis83.svg" alt="Plana 25.15" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">PLANA 25.15</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 2.5cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir mat finition pores bouchées</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 24,72€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-PLANA-20.20-Noir-1239/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/alba15-croquis.svg" alt="Cube" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">CUBE</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 2cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir mat finition pores bouchées</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 25,85€*</div>
                    </Link>
                  </li>
                  <li style={{height: 210, width: 240, border: "1px solid #ddd", display: "flex", margin: "10px 0", justifyContent: "center"}}>
                    <Link to="/cadre/Cadre-bois-PLANA-40.16-Noir-1260/" className="ml-4 mr-4 p-3 pt-5" style={{display: "flex", alignSelf: "flex-end", flexDirection: "column"}}>
                      <img src="https://api.producadre.fr/upload/all_croquis_svg/ecmoulding-1.svg" alt="ABEL" style={{margin: "0 auto", width: "100%", maxWidth: 100, maxHeight: 100, display: "block"}} />
                      <div className="mt-2">ABEL</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Largeur 4.1cm</div>
                      <div style={{ color: "#000", fontSize: "0.7rem" }}>Noir mat finition pores bouchées</div>
                      <div style={{ color: "#822", fontSize: "0.7rem" }}>A partir de 37,81€*</div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-5 has-text-right" style={{fontSize: "0.8rem", color: "#888"}}>* prix pour un cadre photo A5 15x21cm avec isorel, passe partout blanc, plexiglas normal traité anti-UV</div>
              <div className="has-text-centered mt-5">
                <p style={{fontSize: "1.2rem"}}>Retrouvez notre collection complète de cadres pour photo noirs aux liens suivants :</p>
                <ul>
                  <li><Link to="/cadres/Cadre-bois/">Cadre bois</Link></li>
                  <li><Link to="/cadres/Cadre-aluminium/">Cadre aluminium</Link></li>
                  <li><Link to="/cadres/Caisse-americaine/">Caisse américaine</Link></li>
                  <li><Link to="/cadres/noir/">Cadre noir</Link></li>
                  <li><Link to="/cadres/50x70/">Cadre 50x70</Link></li>
                  <li><Link to="/cadres/30x40/">Cadre 30x40</Link></li>
                  <li><Link to="/cadres/40x50/">Cadre 40x50</Link></li>
                  <li><Link to="/cadres/42x59.4/">Cadre A2</Link></li>
                  <li><Link to="/cadres/29.7x42/">Cadre A3</Link></li>
                </ul>
                <p style={{fontSize: "1.2rem"}}>Si vous souhaitez faire réaliser le tirage photo, rendez-vous directement dans le menu <Link to="/tirage-photo-encadre/">tirage photo</Link></p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

CadrePhotoNoir.propTypes = {
  identifiants: PropTypes.object,
  location: PropTypes.object,
}

export default CadrePhotoNoir
